import React from 'react'
import { cn } from '#app/utils/misc.tsx'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './ui/tooltip'

type IconButtonProps = {
  variant?: 'circle' | 'default'
  tooltipContent?: string
  disabled?: boolean
}

const IconButton = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & IconButtonProps
>(
  (
    {
      className,
      variant,
      tooltipContent,
      disabled,
      children,
      onClick,
      ...props
    },
    ref,
  ) => {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <div
              ref={ref}
              className={cn(
                'p-2',
                variant === 'circle' ? 'rounded-full' : 'rounded-md',
                disabled
                  ? 'opacity-50 hover:cursor-not-allowed'
                  : 'hover:cursor-pointer hover:bg-gray-200',
                className,
              )}
              onClick={disabled ? undefined : onClick}
              {...props}
              aria-disabled={disabled}
            >
              {children}
            </div>
          </TooltipTrigger>
          {tooltipContent != null && (
            <TooltipContent>{tooltipContent}</TooltipContent>
          )}
        </Tooltip>
      </TooltipProvider>
    )
  },
)
IconButton.displayName = 'IconButton'

export { IconButton }
